/*** Yopt css overrides ***/
/*************************/
.yotpo-widget-override-css.yotpo-widget-referral-widget {
	max-height: unset !important;
}

.main-widget .yotpo-label-container {
	text-align: center;
}

#dg-chat {
	z-index: 2;
}

.product #dg-chat, .cart #dg-chat {
	display: none;
}

.swiper-slide {
	-webkit-transform: translateZ(0);
	-webkit-backface-visibility: hidden;
}

.slick-track,
.slick-list {
	-webkit-perspective: 2000;
	-webkit-backface-visibility: hidden;
}